import styled from '@emotion/styled'
import Loading from 'components/Loading'

export default styled(({ children, loading, ...props }) => (
  <button {...props}>{loading ? <Loading /> : children}</button>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  padding: ${(props) => (props.reduced ? '.5rem 1rem' : '1rem 2rem')};
  font-weight: bold;
  font-size: ${(props) => (props.reduced ? '1rem' : '1.25rem')};
  background-color: ${(props) => (props.primary ? props.theme.colors.primaryText : props.theme.colors.primary)};
  color: ${(props) => (props.primary ? props.theme.colors.primary : props.theme.colors.primaryText)};
  border: 1px solid ${(props) => props.theme.colors.primaryText};

  &:active {
    background-color: ${(props) => (props.primary ? props.theme.colors.primary : props.theme.colors.primaryText)};
    color: ${(props) => (props.primary ? props.theme.colors.primaryText : props.theme.colors.primary)};
  }

  &:disabled {
    opacity: 0.4;
    cursor: disabled;
  }
`
