import useAuth from 'hooks/useAuth'
import { useCallback, useRef, useState } from 'react'

export default function useRegister() {
  const { handleRegister } = useAuth()

  const usernameInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const [error, setError] = useState(null)

  const submit = useCallback(
    (event) => {
      event.preventDefault()

      const username = usernameInputRef.current?.value
      const password = passwordInputRef.current?.value

      if (!username) return setError('Please enter a username')
      if (!password) return setError('Please enter a password')
      if (password.length < 6) return setError('Password must be at least 6 characters long')

      setError(null)
      handleRegister(username, password).catch(setError)
    },
    [handleRegister]
  )

  return { usernameInputRef, passwordInputRef, submit, error }
}
