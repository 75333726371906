import useAuth from 'hooks/useAuth'
import { useCallback, useRef, useState } from 'react'

export default function useLogin() {
  const { handleLogin } = useAuth()

  const usernameInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const [error, setError] = useState(null)

  const submit = useCallback(
    (event) => {
      event.preventDefault()

      const username = usernameInputRef.current?.value
      const password = passwordInputRef.current?.value

      if (!username) return setError('Please enter a username')
      if (!password) return setError('Please enter a password')

      setError(null)
      handleLogin(username, password).catch(setError)
    },
    [handleLogin]
  )

  return { usernameInputRef, passwordInputRef, submit, error }
}
